import React, { useState } from "react";
import { Box, Typography, Modal, Card } from "@mui/material";
import Confetti from "react-confetti";
import draw from "../assets/draw.jpg";
import santa from "../assets/santa.mp3";
import axios from "axios";
import "./GiftBox.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80%", 
  maxWidth: "1000px", 
  bgcolor: "background.paper",
  boxShadow: 2,
  p: 4,
  borderRadius: "100px",
  backgroundImage: `url(${draw})`, 
  backgroundSize: "cover", 
};

const Drawer = ({ open, onClose }) => {
  const [boxActive, setBoxActive] = useState(false);
  const [audio] = useState(new Audio(santa));

  const [fullName, setFullName] = useState("");

  const loggedUser = localStorage.getItem('serviceno');

  const handleBoxClick = async (e) => {
    e.preventDefault();
    if (!boxActive) {
      // Play audio only if the box is not already active
      audio.play();
    }
    setBoxActive(!boxActive);

    try {
      const response = await axios.post(
        `https://esystems.cdl.lk/SecretSanta/SecretSantaDraw/SantaDraw?SantaSender=${loggedUser}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if the response status code is 200
      if (response.status === 200) {
        const Fullname = response.data.ResultSet.FullName;
        setFullName(Fullname);
        console.log("FullName:", Fullname);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }

  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{
            textAlign: "center",
            color: "Menu",
          }}
          id="modal-modal-title"
          variant="h4"
          component="h4"
        >
          Click on the Box
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "red",
          }}
          id="modal-modal-title"
          variant="h6"
          component="h6"
        >
        ** The maximum amount for the gift is RS.1000.** 
        </Typography>
        {boxActive && <Confetti />} 
        <div
          className={`center-container ${boxActive ? "box-body-active" : ""}`}
          onClick={handleBoxClick}
        >
          <div className="box-body">
            <Card
              class="img"
              style={{
                width: "100%",
                height: "50%",
                textAlign: "center",
                background: "#B8E3FF",
                WebkitBackdropFilter: "blur(1px)",
                backdropFilter: "blur(1px)",
                border: "5px solid rgba(255, 255, 255, 2)",
                borderRadius: "25px",
              }}
            >
              <Typography
                sx={{
                  mt: "15%",
                  color: "red",
                  fontWeight: "bold",
                }}
                id="modal-modal-title"
                variant="h5"
                component="h5"
              >
                {fullName}
              </Typography>
            </Card>

            <div className="box-lid">
              <div className="box-bowtie"></div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Drawer;
