import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Snowfall from "react-snowfall";
import bell from "../assets/bell.mp3";
import back from "../assets/back.jpg";
import DTS from "../assets/DTS.png";

function Log() {
  const [audio] = useState(new Audio(bell));
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [showVerification, setShowVerification] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [receivedOTP, setReceivedOTP] = useState("");
  const [otpError, setOtpError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const handleInteraction = () => {
      audio.loop = true;
      audio.play();
      document.removeEventListener("click", handleInteraction);
    };

    document.addEventListener("click", handleInteraction);

    return () => {
      audio.pause();
      audio.currentTime = 0;
      document.removeEventListener("click", handleInteraction);
    };
  }, [audio]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!phone.trim()) {
      setPhoneError("Phone number is required");
      return;
    }

    setPhoneError("");
    setPhone("");
    setShowVerification(true);

    try {
      const response = await axios.post(
        `https://esystems.cdl.lk/SecretSanta/Login/Login?PhoneNo=${phone}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if the response status code is 200
      if (response.status === 200) {
        const OTPNum = response.data.Result.ResultSet.OTP;
        const Sno = response.data.Result.ResultSet.ServiceNo;
        const Name = response.data.Result.ResultSet.FullName;
        setReceivedOTP(OTPNum);
        console.log("OTP:", OTPNum);
        console.log("ServiceNo:", Sno);
        localStorage.setItem("serviceno", Sno);
        localStorage.setItem("fullname", Name);
        // localStorage.removeItem('serviceno', Sno);
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleVerify = () => {
    if (otp.trim() === "") {
      setOtpError("Please enter the OTP");
      return;
    }

    if (otp == receivedOTP) {
      setIsVerified(true);
      setShowVerification(false);
      navigate("/home");
    } else {
      setOtpError("Incorrect OTP. Please try again.");
    }
  };

  return (
    <Grid
      container
      component="main"
      xs={false}
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: `url(${back})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        justifyContent: "center",
        pr: "10%",
        pl: "10%",
      }}
    >
      <Snowfall snowflakeCount={200} color="white" style={{ zIndex: 9999 }} />
      <CssBaseline />

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        xl={3}
        sx={{
          background: "rgba(163,189,222,0.2);",
          WebkitBackdropFilter: "blur(1px)",
          backdropFilter: "blur(1px)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          borderRadius: "25px",
        }}
      >
        <Box>
          <Box
            sx={{
              my: 4,
              mx: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                my: 2,
                fontFamily: "segoe ui",
                fontWeight: "bold",
                color: "blue",
              }}
            >
              Log in to your Account
            </Typography>

            <Typography
              component="h1"
              sx={{
                fontFamily: "segoe ui",
                color: "blue",
              }}
            >
              Welcome back!
            </Typography>
          </Box>

          <Box
            sx={{
              my: 1,
              mx: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {showVerification && !isVerified ? (
              <Box component="form" noValidate onSubmit={handleVerify}>
                <Grid
                  container
                  direction="column" 
                  spacing={1} 
                >
                  <Grid item>
                    <TextField
                      margin="normal"
                      autoComplete="Phone"
                      name="otp"
                      id="otp"
                      label="OTP"
                      autoFocus
                      fullWidth
                      autoCapitalize="none"
                      autocompletetype="otp"
                      inputMode="otp"
                      type="otp"
                      variant="outlined"
                      size="small"
                      value={otp}
                      onChange={(event) => {
                        setOtp(event.target.value);
                        setOtpError("");
                      }}
                      style={{
                        backgroundColor: "rgba(255,255,255,0.5)",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="error"
                      align="center"
                     
                    >
                      {otpError}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      onClick={handleVerify}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      Verify
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                sx={{ mt: -2 }}
                onSubmit={handleLogin}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography align="center">+94</Typography>

                  <TextField
                    margin="normal"
                    autoComplete="Phone"
                    name="Phone"
                    id="Phone"
                    label="Phone"
                    placeholder="7XXXXXXXX"
                    autoFocus
                    fullWidth
                    autoCapitalize="none"
                    autocompletetype="Phone"
                    inputMode="tel"
                    type="tel"
                    variant="outlined"
                    size="small"
                    value={phone}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const limitedValue = sanitizedValue.slice(0, 9);
                      setPhone(limitedValue);
                    }}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                      maxLength: 9, 
                      pattern: "[0-9]*", 
                    }}
                    style={{
                      backgroundColor: "rgba(255,255,255,0.5)",
                      borderRadius: "4px",
                      margin: "10px",
                    }}
                  />
                </Grid>
                <Typography
                  variant="body2"
                  color="error"
                  align="center"
                  sx={{ mt: 1 }}
                >
                  {phoneError}
                </Typography>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 1 }}
                >
                  Login
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <img
        src={DTS}
        alt="Logo"
        style={{
          width: "20%",
          height: "5%",
          alignSelf: "center", 
          position: "absolute",
          bottom: "4%", 
        }}
      />
    </Grid>
  );
}

export default Log;
