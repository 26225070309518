import React, { useState, useEffect } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { OrbitControls } from "@react-three/drei";
import * as THREE from "three"; // Import THREE library

import dear from "../assets/dear.glb";

const DearScene = React.memo(() => {
  const [dearModel, setDearModel] = useState();

  useEffect(() => {
    const dearLoader = new GLTFLoader();
    dearLoader.load(dear, (gltf) => {
      setDearModel(gltf.scene);

      // Assuming there is only one animation clip
      const animation = gltf.animations[0];
      const mixer = new THREE.AnimationMixer(gltf.scene);
      const action = mixer.clipAction(animation);
      action.play();

      // Set up animation loop
      const animate = () => {
        mixer.update(0.016); // Update animation
        requestAnimationFrame(animate);
      };

      animate();
    });
  }, []);

  if (!dearModel) {
    return null; // or render a loading indicator
  }

  return (
    <>
      <primitive
        object={dearModel}
        position={[2.1, 0.9, 0]}
        scale={[0.2, 0.2, 0.2]}
        rotation={[0, Math.PI / 1, 0]}
      />

      <OrbitControls
        enableDamping
        dampingFactor={0.25}
        target={[0, 1, 0]}
        enableZoom
        zoomSpeed={0.5}
        center={[0, 0, 0]}
      />
    </>
  );
});

export default DearScene;
