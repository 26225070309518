import React, { useState, useEffect, useRef } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { OrbitControls } from "@react-three/drei";
import { useFrame } from "react-three-fiber";
import xmas from "../assets/xmas.glb";

const XmasScene = React.memo(({ autoRun, onClick }) => {
  const [xmasModel, setXmasModel] = useState();
  const groupRef = useRef();

  useEffect(() => {
    const xmasLoader = new GLTFLoader();
    xmasLoader.load(xmas, (gltf) => {
      setXmasModel(gltf.scene);
    });
  }, []);

  useFrame(({ clock }) => {
    if (autoRun && groupRef.current) {
      // Slow down the Xmas model rotation speed
      groupRef.current.rotation.y = -0.4 * clock.elapsedTime;
    }
  });

  if (!xmasModel) {
    return null; 
  }

  return (
    <group ref={groupRef} onClick={onClick}>
      <primitive object={xmasModel} position={[0, 0, 0]} />

      <OrbitControls
        enableDamping
        dampingFactor={0.25}
        rotateSpeed={0}
        target={[0, 1, 0]}
        enableZoom
        zoomSpeed={0.5}
        center={[0, 0, 0]}
      />
    </group>
  );
});

export default XmasScene;
