import React, { useState, useEffect, useRef } from "react";
import Snowfall from "react-snowfall";
import { Canvas, useFrame } from "react-three-fiber";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { Clouds, OrbitControls, Sky, Stars } from "@react-three/drei";
import sky from "../assets/sky.glb";
import XmasScene from "./ThreeDModel";
import DearScene from "./Dear";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; 
import Swal from "sweetalert2";
import SantaScene from "./Santa";
import Drawer from "./Drawer";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import bellSound from "../assets/bell.mp3";



const Home = () => {
  const [skyModel, setSkyModel] = useState();
  const [autoRunXmas, setAutoRunXmas] = useState(true);
  const navigate = useNavigate();
  // Initialize the useNavigate hook
  const [open, setOpen] = useState(false);
  const [audio] = useState(new Audio(bellSound));
  audio.loop = true;
  const loggedUser = localStorage.getItem('serviceno');

  useEffect(() => {
    // Autoplay the audio when the component mounts
    audio.play();

    // Cleanup function to pause the audio when the component unmounts
    return () => {
      audio.pause();
    };
  }, [audio]);

  useEffect(() => {
    const skyLoader = new GLTFLoader();
    skyLoader.load(sky, (gltf) => {
      setSkyModel(gltf.scene);
    });
   
    const User = localStorage.getItem('fullname');
    Swal.fire({
      title:  'Hey '+ User + '!. Welcome to the Xmas spirit ',
      text: ' Wishing you a Merry Christmas filled with joy, warmth, and tech-tacular celebrations!',
      width: 600,
      padding: "3em",
      color: "#716add",
      backgroundColor: "rgba(153, 10, 0, 0.2)",
      backdrop: `
        rgba(0,0,123,0.3)
        url("https://www.icegif.com/wp-content/uploads/nikolaus-icegif-9.gif")
        left top
        no-repeat
      `,
      timer: 5000, 
      showConfirmButton: false 
    });
    

  }, []);

  const handleXmasClick = () => {
    setAutoRunXmas(!autoRunXmas);
  };

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleLogout = () => {
 
    localStorage.removeItem('serviceno');
 
    navigate("/");
  };

  if (!skyModel) {
    return null; 
  }

  const SkyRotation = () => {
    const groupRef = useRef();

    useFrame(() => {
      groupRef.current.rotation.y -= 0.005;
    });

    return <primitive object={skyModel} position={[0, 1, 10]} ref={groupRef} />;
  };

  return (
    <>
      <Snowfall snowflakeCount={200} color="white" style={{ zIndex: 9999 }} />
      <Canvas
        style={{ width: "100%", height: "100vh" }}
        camera={{ position: [6, 3, 0], fov: 50 }}
      >
        <ambientLight intensity={1.8} />
        <spotLight position={[0, 0, 0]} angle={0.15} penumbra={1} />
        <pointLight position={[0, 0, 0]} />
        <SkyRotation />
        <XmasScene autoRun={autoRunXmas} onClick={handleXmasClick} />
        <DearScene />
        <SantaScene />
        <OrbitControls
          enableDamping
          dampingFactor={0.25}
          target={[0, 1, 0]}
          enableZoom
          zoomSpeed={0}
          center={[0, 0, 0]}
        />
      </Canvas>
  
  <div>
  <Button
    variant="contained"
    style={{
      position: "absolute",
      bottom: "2%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "5%",
      backgroundColor: "red",
    }}
    onClick={handleLogout}
  >
    <ExitToAppIcon/>
  </Button>

  <Button
    variant="contained"
    style={{
      position: "absolute",
      bottom: "10%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "5%",
      backgroundColor: "blueviolet",
    }}
    onClick={handleButtonClick}
  >
    Click Here to Play Secret Santa
  </Button>

</div>

      <Drawer open={open} onClose={handleCloseModal} />
    </>
  );
};

export default Home;
